<template lang="pug">
.user-create-edit-dialog 
  v-dialog(
    max-width="600"
    persistent
    v-model="isOpen"
  )
    FeathersVuexFormWrapper( 
      :item="newUser"
    )
      template( v-slot="{ clone, save, reset }" )
        v-form( ref="createEditForm" v-model="valid" )
          v-card
            v-card-title.bg-gray-200.justify-between
              div {{ title }}
              v-spacer
              div
                v-btn(
                  fab
                  small
                  depressed
                  @click="$emit('close')"
                )
                  v-icon mdi-close

            v-card-text.pa-8.py-10
              .d-flex.gap-8
                v-text-field(
                  outlined
                  dense
                  :rules="[rules.required]"
                  label="Firstname"
                  v-model="clone.firstName"
                )

                v-text-field(
                  outlined
                  dense
                  :rules="[rules.required]"
                  label="Lastname"
                  v-model="clone.lastName"
                )

              v-text-field(
                outlined
                dense
                :rules="[rules.email]"
                label="Email"
                v-model="clone.email"
              )

              StateList(
                v-model="clone.stateId"
              )

              v-checkbox(
                v-if="$can('create', { roleId: 2 })"
                v-model="makeSuperAdmin"
                label="Make Super Admin"
                value="yes"
                color="blue lighten-2"
                hide-details
              )

            v-card-actions( right ).bg-gray-100
              v-btn(
                depressed
                @click="$emit('close')"
              ) Cancel 
              v-btn(
                depressed
                :disabled="!valid"
                @click="saveAdminUser(clone, save, reset)"
                color="primary"
              ) {{ !!editingUser ? 'Save' : 'Add' }}
</template>

<script>
import { ref } from '@vue/composition-api'
import StateList from '@/components/StateList'
import formValidations from '../../helpers/formValidations'

export default {
  name: 'AdminUserCreateEditDialog',

  props: {
    title: {
      type: String,
      default: 'Add Admin'
    },
    isOpen: Boolean,
    editingUser: Object
  },

  components: {
    StateList
  },

  methods: {
    async saveAdminUser(clone, save, reset) {
      try {
        if (!this.editingUser)
          clone.roleId = this.makeSuperAdmin ? 1 : 2

        await save()
        this.$snackSuccess('Successfully added admin user')
        this.$emit('close', true)
      } catch (e) {
        this.$snackError(e)
      } finally {
        reset()
        this.$refs.createEditForm.reset()
      }
    }
  },

  setup(props, { root: { $FeathersVuex, $can } }) {
    const { valid, rules } = formValidations()
    const makeSuperAdmin = ref(false)
    const { User } = $FeathersVuex.api
    const newUser = new User(props.editingUser || { status: 'active', stateId: 1 })

    return {
      makeSuperAdmin,
      newUser,
      rules,
      valid,
      canMakeAdmin: $can('create', { roleId: 1 })
    }
  }
}
</script>

<style>

</style>